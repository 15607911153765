import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Component for user auth page. */
@Component({
	selector: 'scriptaw-auth-page',
	templateUrl: './auth-page.component.html',
	styleUrls: ['./auth-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPageComponent {}
